
























































































































import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';

import InterviewQuestionsList from '@/components/interview/InterviewQuestionsList.vue';
import JobTitle from '@/components/job/JobTitle.vue';

import VolumeTracker from '@/components/audio/VolumeTracker.vue';
import StartRecord from '@/components/video/BetaStartRecord.vue';

import Alert from '@/components/alerts/Alert.vue';
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import EntrevistaService from '@/core/Services/Candidato/Entrevista';
import RoomService from '@/core/Services/Video/Room';
import { CandidaturaEntity, VagaEntity, VagaPerguntaEntity } from '@/core/models/Entities';
import { LocalRecorder, LocalRecorderState, OpenViduError } from 'openvidu-browser';

import vueAwesomeCountdown from 'vue-awesome-countdown';
Vue.use(vueAwesomeCountdown, "countdown");

@Component({
  components: {
    JobTitle,
    InterviewQuestionsList,
    StartRecord,
    VolumeTracker,
    JobsHeaderNavigation
  }
})
export default class Entrevista extends Vue {
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private entrevistaService: EntrevistaService = new EntrevistaService();
  private candidatura: CandidaturaEntity | null = null;
  private warningAlert!: Alert;
  private finishedEntrevistaAlert!: Alert;
  private errorAlert!: Alert;
  public room: RoomService;
  public hasSidebar: boolean = true;
  public showPergunta: boolean = false;

  // A faixa de volume vária de -100 a 0s
  private volume: number = -100;

  private introAnswered = false;
  private videoStarted = false;
  private logVolume: boolean = true;
  private videoProgressTime: number = 0;

  constructor() {
    super();
    this.room = new RoomService();
  }

  private get purpose(): number {
    return this.introAnswered
      ? EVideoPurpose.Interview
      : EVideoPurpose.TestOnly;
  }

  private get startMessage(): string {
    return !this.introAnswered ? this.$t('Start test').toString() : this.$t('Start recording').toString();
  }

  public get vaga(): VagaEntity | undefined {
    return this.candidatura?.vaga;
  }

  private get perguntas(): Array<VagaPerguntaEntity> {
    return this.candidatura!.vaga.perguntas;
  }

  private get currPergunta(): VagaPerguntaEntity {
    return this.perguntas[this.currIndex];
  }

  // Indice atual é sempre o indice da primeira pergunta não respondida.
  private get currIndex(): number {
    return this.perguntas.findIndex((pergunta: VagaPerguntaEntity) => {
      return !pergunta.answered;
    });
  }

  private get hasMorePerguntas(): boolean {
    return (
      -1 !==
      this.perguntas.findIndex(
        (pergunta: VagaPerguntaEntity, index: number) => {
          return !pergunta.answered && index > this.currIndex;
        }
      )
    );
  }

  private get questionMaxTime() {
    return this.introAnswered ? this.currPergunta.tempo * 60 * 1000 : 0;
  }

  public resetVolume(): void {
    this.volume = -100;
  }
  get isSafari() {
    return false; // (!!window['safari'] || /^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }

  public created(): void {
    
    if(this.$route.name == "EntrevistaNoSidebar") {
      this.hasSidebar = false;
    }

    this.initWarnings();

    this.checkIfCanMakeInterview();

    this.candidaturaService
      .fetch(+this.$route.params.id)
      .then((candidatura: CandidaturaEntity) => {
        this.candidatura = candidatura;

        if (!this.candidatura.canAnswer || this.candidatura.status == "REVISAOCURRICULAR") {
          this.$router.push({
            name: "Candidaturas",
            query: { lang: this.$i18n.locale }
          });
          return;
        }    
      })
      .catch(() => {
        // Erro
      });
  }

  public initWarnings() {
    this.warningAlert = new Alert();
    this.warningAlert.title = this.$t("Attention");
    this.warningAlert.addMsg(
      this.$t(
        "Once the interview starts, you will not be able to pause or record again"
      )
    );
    this.warningAlert.addMsg(this.$t("Pay attention to the time you have to answer each question"));
    this.warningAlert.on("hide", () => {
      this.goToFirstQuestion();
      this.warningAlert.$destroy();
    });

    this.finishedEntrevistaAlert = new Alert();
    this.finishedEntrevistaAlert.title = this.$t("Congratulations");
    this.finishedEntrevistaAlert.addMsg(
      this.$t(
        "Your interview has been sent successfully. We wish you the best of luck."
      ).toString()
    );

    this.errorAlert = new Alert();
    this.errorAlert.title = this.$t("Error");
  }

  public showCountDownUrlCallback() {
    this.$bvModal.show('modalurlCallbackAlert');
    setTimeout(()=> {
      if (this.$refs.urlCallbackCounter)
        (<any>this.$refs.urlCallbackCounter).startCountdown(true);
    },500)
  }

  public forwardToUrlCallback() {
    this.$bvModal.hide('modalurlCallbackAlert');
    if(this.candidatura?.urlCallback)
      location.href = this.candidatura?.urlCallback;
  }
  public stopCounterPropagation() {
    if(this.$refs.urlCallbackCounter)
      (<any>this.$refs.urlCallbackCounter).finishCountdown();
    //this.forwardToUrlCallback();
  }

  public checkIfCanMakeInterview() {
    this.entrevistaService
    .update({id: this.$route.params.id})
    .catch((err) => {
      this.warningAlert = new Alert();
      this.warningAlert.title = this.$t("Attention");
      this.warningAlert.addMsg(
        this.$t(
          "This position no longer accepts applications"
        )
      );
      this.warningAlert.callBoxWarn().finally(() => {
        this.$router.push({
          name: "Candidaturas",
          query: { lang: this.$i18n.locale }
        });
      })
    })
  }

  public onVolumeChange(value: number) {
    this.volume = value;
  }

  public showWarningModal() {
    this.warningAlert.callBoxTime();
  }

  public goToFirstQuestion(): void {
    this.introAnswered = true;
    this.logVolume = false;
    this.resetVolume();
    this.changeQuestion();

    this.$nextTick(() => {
      (<StartRecord>this.$refs.recorder).testingPhase = false;
        (<StartRecord>this.$refs.recorder).startProcess();
    });
  }

  public nextQuestion(): void {
    // Atualizar esse valor automaticamente atualiza a computed prop currIndex
    this.currPergunta.answered = true;
    this.changeQuestion();
    if ((<StartRecord>this.$refs.recorder) && !(<StartRecord>this.$refs.recorder).roomManager.publisher) {
      (<StartRecord>this.$refs.recorder).startProcess();
    }
  }

  public changeQuestion(): void {
    this.showPergunta = false;
    if((<StartRecord>this.$refs.recorder))
        (<StartRecord>this.$refs.recorder).roomManager.removePublisher();
  }

  public startedProcess(): void {
    this.videoStarted = true;
  }

  public videoCounterProcess(time: number): void {
    this.videoProgressTime = time;
  }

  public startCountDownStarted() {
    this.showPergunta = true;
  }

  public reloadForcingStreamRecording() {}

  public async recordEnded(data: object, roomData: any, transcriptionArray?: SpeechRecognitionAlternative[]): Promise<void> {
    this.videoProgressTime = 0;
    /* console.log("save interview") */
    if(data instanceof LocalRecorder) {
      //is local Recorder
      if(data.state == LocalRecorderState.FINISHED) {
        console.log('==init.saveFile')
        this.saveLocalFile(data, roomData, transcriptionArray)
      } else {
        console.error("No Recoded Data",data)
        await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
        location.href = location.protocol + "//" + location.host + location.pathname + '?lang='+this.$i18n.locale+"&fs=1";
      }
    } else {
      const supplier: any = (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio || this.isSafari) ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
      this.entrevistaService
      .saveInterview(supplier, this.currPergunta.id, data, transcriptionArray)
      .then(async () => {
        await this.successSavedInterview(roomData, false);
      }).catch(async () => {
        await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');  
        location.reload()
      })
    }
  }

  public saveLocalFile(data: LocalRecorder, roomData: any, transcriptionArray?: SpeechRecognitionAlternative[]) {
    this.entrevistaService
    .saveInterviewFile(data.getBlob(), this.currPergunta.id, transcriptionArray)
    .then(async () => {
      await this.successSavedInterview(roomData, true);
    }).catch(async (err) => {
      console.error(err)
      await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');  
      location.href = location.protocol + "//" + location.host + location.pathname + '?lang='+this.$i18n.locale+"&fs=1";
    })
  }

  public async successSavedInterview(roomData: any, localRecorder: boolean) {
    const supplier: any = (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio || this.isSafari) ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
    if (this.hasMorePerguntas) {
      this.nextQuestion();
      
      if(supplier === EVideoSupplier.Openvidu && !localRecorder) {
        try {
          await this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
        } catch(Exception) {
          console.log('Falha ao Fechar a Sala OV')
        }
      }
        
    } else {
      
      if((<StartRecord>this.$refs.recorder))
        (<StartRecord>this.$refs.recorder).removeListeners();

      if(supplier === EVideoSupplier.Openvidu && !localRecorder) {
        try {
          await this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
        } catch(Exception) {
          console.log('Falha ao Fechar a Sala OV')
        }
      }

      if (this.candidatura?.urlCallback && this.candidatura.urlCallback?.indexOf('http') > -1) {         
        this.showCountDownUrlCallback()
      } else if(this.$route.name == "EntrevistaNoSidebar") {
        this.candidatura!.urlCallback = (this.candidatura?.urlCallback||"https://jobecam.com/candidato");
        this.showCountDownUrlCallback()
      } else {
        //TODO call vaga end 
        this.finishedEntrevistaAlert.addMsg(this.$t("Please note that it is important to keep your profile up to date. Good luck!"));
        let obj: any = {
          b1Title: this.$t("Find more jobs").toString(),
          b2Title: this.$t("Update my profile").toString(),
          b1CallBack: () => { 
            this.$router.push({ path: '/vagas', query: { lang: this.$i18n.locale }})
          },
          b2CallBack: () => {
            this.$router.push({ path: '/edit', query: { lang: this.$i18n.locale }})
          }
        };
        this.finishedEntrevistaAlert.callBoxCheckWithTitleAndDoubleButtons(obj);
      }  
    }
  }

  public forceEnded(roomData: any): void {
    const supplier: any = (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio || this.isSafari) ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
    if(supplier === EVideoSupplier.Openvidu)
      this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
  }

  public handleOpenviduError(error: OpenViduError) {
    this.errorAlert.addMsg(this.$t(error.name));
    this.errorAlert.callBoxError();
  }
}
